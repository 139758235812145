import { Injectable } from '@angular/core';
import { LocalStorageService } from '../localstorage/localstorage.service';


@Injectable({
  providedIn: 'root',
})
export class TokenService {
  // private myTokenCache!: Observable<RefreshTokenResponse>;
  private myToken!: string|null;
  constructor(
    private localStorageService: LocalStorageService
  ) {}

    

  get token(): string|null {
    return this.myToken;
  }

  set token(token: string) {
    this.myToken = token;
  }

  public setToken(token:any) {
    this.setTokenToTokenService(token);
    this.setTokenToCookies(token);
  }

  /**
   * set token to cookies
   * @param token
   */
  private setTokenToCookies(token:any) {
    this.localStorageService.setItem('token', token);
  }

  /**
   * set token to token Service
   * @param token
   */
  private setTokenToTokenService(token:any) {
    this.token = token;
  }

  /**
   * clear token
   */
  public clearToken() {
    this.localStorageService.removeItem('token');
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { IProfile } from '../../appInterfaces/IProfile';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  api:string;
  profile = new BehaviorSubject<IProfile|null>(null); 
  loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private apiConfig: HttpConfigService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
      this.api = this.apiConfig.getBaseUrl;
    }

  /**
   * Get Profile
   * @returns 
   */
  getProfileFromAPI(): Observable<any> {
    return this.apiConfig.get(`${this.api}user/profile`);
  }


  /**
   * Get Profile
   * @returns 
   */
  getProfile(){
    this.getProfileFromAPI().subscribe(res=>{
      this.profile.next(res);
    });
  }
  

  
  logout(){
    this.loggedIn.next(false);
    this.localStorageService.removeItem('token');
    this.localStorageService.removeItem('basic-info');
    this.localStorageService.removeItem('app-store-links');
    this.localStorageService.removeItem('social-media-links');
    this.router.navigate(['/']);
  }


  /**
   * Get leaderboard data
   * @returns 
   */
  getLeaderboardData(size:number): Observable<any> {
    return this.apiConfig.get(`${this.api}user/customer/leaderboard/${size}`);
  }

  /**
   * update profile
   * @param user
   * @returns 
   */
  updateProfile(user: IProfile): Observable<any> {
    return this.apiConfig.put(this.api + 'user', user);
  }

  /**
   * update passward
   * @param passwordForm 
   * @returns 
   */
  updatePasswordWithOutOTP(passwordForm: any): Observable<any> {
    return this.apiConfig.put(this.api + 'user/update/password', passwordForm);
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { TokenService } from '../token/token.service';
import { ProfileService } from '../profile/profile.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  api:string;
  private _username: any;
  openLoginDialogClick = new Subject();
  previousAuthStep: string | null = null;
  authStep = new BehaviorSubject<string>('landing');  
  constructor(
    private apiConfig: HttpConfigService,
    private tokenService: TokenService,
    private profileService: ProfileService,
  ) {
      this.api = this.apiConfig.getBaseUrl;
    }


  closeLoginDialog() {
    this.openLoginDialogClick.next(null);
  }

  goBack() {
    if (this.previousAuthStep !== null) {
      this.authStep.next(this.previousAuthStep);
      this.previousAuthStep = null;
    }
  }

  updateAuthStep(newStep: string) {
    this.previousAuthStep = this.authStep.value;
    this.authStep.next(newStep);
  }


  /*------------------------------------------------------------------
    Username Getter/Setter
  -------------------------------------------------------------------*/

  get username(): any {
    return this._username;
  }

  set username(username: any) {
    this._username = username;
  }

  setUserName(username: any) {
    this.username = username;
  }



  /*------------------------------------------------------------------
    Common Apis
  -------------------------------------------------------------------*/

  /**
   * Get Tenant By Username (Mobile || Email) ||
   * @param username
   * @returns
   */
   checkCustomer(data: any): Observable<any> {
     return this.apiConfig.get(`${this.api}user/customer/${data.username}`);
   }



  /*------------------------------------------------------------------
    New Tenant Apis
  -------------------------------------------------------------------*/

  /**
   * For [New Tenant] Request Again OTP For New User:
   * Use getTenant() Method
   */

 


 



  /**
   * Generate OTP for existing User
   * @param username
   * @returns
   */
  //  generateOtp(username: any): Observable<any> {
  //   return this.apiConfig.get(`${this.api}user/generateOTP/username/${username}`);
  // }

  sendOtp(payload:any): Observable<any> {
    return this.apiConfig.post(this.api + 'user/generate/OTP', payload);
  }
  
  signup(user:any): Observable<any> {
    return this.apiConfig.post(this.api + 'user/signUp', user);
  }


  
  /*------------------------------------------------------------------
    Existing Tenant Apis
  -------------------------------------------------------------------*/

  login(user:any): Observable<any> {
    return this.apiConfig.post(`${this.api}authenticate`, user);
  }

  generateEmailOTP(emailId:any): Observable<any> {
    return this.apiConfig.get(this.api + 'user/generateOTP/email/' + emailId);
  }

  generateOTP(mobileNumber:any): Observable<any> {
    return this.apiConfig.get(this.api + 'user/generateOTP/' + mobileNumber);
  }


  updatePassword(passwordForm:any): Observable<any> {
    return this.apiConfig.put(this.api + 'user/password', passwordForm);
  }
  

  /**
   * Post login
   */
  public postLogin(token: string) {
    /** push-notification configuration */
    // this._pushNotificationsService.requestPermission();
    // this._pushNotificationsService.listen();
    
    // this.getAllAccounts();
    this.tokenService.setToken(token);
    // this.getAddresses();
    this.markLoggedIn();
    this.closeLoginDialog();
  }


  /**
   * Mark logged in
   */
  private markLoggedIn() {
    this.profileService.getProfile();
    this.profileService.loggedIn.next(true);
  }


  /**
     * check last otp
     * @param mobileNumber 
     * @returns 
     */
  checkLastOTPDate(mobileNumber: any): Observable<any> {
    return this.apiConfig.get(this.api + 'user/otp/date/' + mobileNumber);
  }


  verifyOTP(payload: any): Observable<any> {
    return this.apiConfig.post(this.api + 'user/verifyOTP', payload);
  }
   


}
